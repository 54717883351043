@media (max-width: 767px) {
  .breadcrumb-ellipsis {
    display: none;
  }
}

@media (max-width: 767px) {
  .breadcrumb-ellipsis {
    display: inline;
  }
}
