.SwitchRoot {
  width: 42px;
  height: 25px;
  background-color: #888886;
  border-radius: 9999px;
  position: relative;
  &.large {
    width: 50px;
    height: 32px;
  }
}
.SwitchRoot[data-state="checked"] {
  background-color: #181818;
}
.SwitchRoot[data-state="checked"].green {
  background-color: #17c500;
}

.SwitchThumb {
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 2px #888886;
  transition: transform 0.5s;
  transform: translateX(2px);
  will-change: transform;
  &.large {
    width: 28px;
    height: 28px;
  }
}

.SwitchThumb[data-state="checked"] {
  transform: translateX(19px);
}
.large {
  .SwitchThumb[data-state="checked"] {
    transform: translateX(20px);
  }
}
