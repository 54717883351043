@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon.woff?c97tt0") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-green-check:before {
  content: "\e930";
  color: #3fbf2e;
}
.icon-red-reject .path1:before {
  content: "\e964";
  color: rgb(235, 0, 0);
}
.icon-red-reject .path2:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-warning .path1:before {
  content: "\e959";
  color: rgb(255, 176, 15);
}

.icon-warning .path2:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
