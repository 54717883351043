.slide {
  position: fixed;
  inset: 0;
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  z-index: 20;
  overflow: hidden;
  transition: all 0.3s linear;
  opacity: 0;
  height: 100vh;
  z-index: -1;
  .content {
    height: fit-content;
    width: fit-content;
    z-index: 40;
    position: absolute;
    &.bottom {
      bottom: -100%;
      left: 50%;
      transform: translateX(-50%);
      transition: bottom 0.2s linear;
    }
    &.top {
      top: -100%;
      left: 50%;
      transform: translateX(-50%);
      transition: top 0.2s linear;
    }
    &.left {
      left: -100%;
      transition: left 0.2s linear;
    }
    &.right {
      right: -100%;
      transition: right 0.2s linear;
    }
    &.center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .overlay {
    z-index: 30;
    background-color: rgba($color: #000000, $alpha: 0.4);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
}

.slide.show {
  opacity: 1;
  z-index: 50;
  .content {
    &.bottom {
      bottom: 0;
    }
    &.top {
      top: 0;
    }
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    &.center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media (max-width: 768px) {
  .slide {
    .content {
      z-index: 40;
      position: absolute;
    }
  }
}
