.content {
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6d6d6d;
  }
  &::-webkit-scrollbar-track {
    background-color: #e0e0e0;
  }
}
