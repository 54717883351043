.filterContent {
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6d6d6d;
  }
  &::-webkit-scrollbar-track {
    background-color: #e0e0e0;
  }
}
.accordion {
  details[open] {
    summary,
    .content {
      background: #f6f6f6;
    }
  }
}
.bestBuysBanner {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  background: linear-gradient(90deg, #e94323 0%, #f7c645 99.23%);
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}
